import { a, useSpring } from "@react-spring/web";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import "./App.css";
import MainCanvas from "./components/MainCanvas";
import { useStore } from "./store";
import * as easings from "d3-ease";
import spinner from "./assets/images/decade-loader.gif";

export default observer(() => {
  const store = useStore();
  const [isLoaded, setLoaded] = useState(false);

  async function init() {
    await store.init();
    setLoaded(true);
  }

  useEffect(() => {
    init();
  }, []);

  const opacity = useSpring({
    value: store.isInitialised ? 0 : 1,
    immediate: !store.isInitialised,
    config: {
      duration: 500,
      delay: 1000,
      easing: easings.easeCubicInOut,
    },
    native: true,
  });

  return (
    <>
      {isLoaded && <MainCanvas />}
      <a.div
        className="loadingContainer"
        style={{ opacity: opacity.value as any }}
      >
        <img src={spinner} />
      </a.div>
    </>
  );
});

import { useStore } from "../store";

export default function useCursor(isActive: boolean, cursor = "pointer") {
  const store = useStore();

  function handleEnter(event: any) {
    if (isActive) {
      event.stopPropagation();
      store.setCursor(cursor);
    }
  }

  function handleExit(event: any) {
    if (isActive) {
      event.stopPropagation();
      store.setCursor(store.isDragging ? "grabbing" : "grab");
    }
  }

  return [handleEnter, handleExit];
}

import { a, useTransition } from "@react-spring/web";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { ReactComponent as DownArrow } from "../assets/svg/SVD-10-arrow-down.svg";
import { ReactComponent as UpArrow } from "../assets/svg/SVD-10-arrow-up.svg";
import { useStore } from "../store";

const sectionTitleKeys = ["intro", "thePast", "thePresent", "theFuture"];

export default observer(() => {
  const store = useStore();
  const { isViewingItem, activeSection } = store;

  const { t } = useTranslation();

  const upKey = sectionTitleKeys[activeSection - 1];
  const downKey = sectionTitleKeys[activeSection + 1];
  const upArrowText = upKey && !isViewingItem && t(upKey);
  const downArrowText = downKey && !isViewingItem && t(downKey);

  const upTransition = useTransition(upArrowText, {
    from: { position: "absolute", opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const downTransition = useTransition(downArrowText, {
    from: { position: "absolute", opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <>
      {upTransition(
        (style, item) =>
          item && (
            <a.div
              //@ts-ignore
              style={{ ...style }}
              className="navArrow topArrow"
              onClick={() => store.changeSection(-1)}
            >
              <UpArrow />
              <p>{item}</p>
            </a.div>
          )
      )}
      {downTransition(
        (style, item) =>
          item && (
            <a.div
              //@ts-ignore
              style={{ ...style }}
              className="navArrow bottomArrow"
              onClick={() => store.changeSection(1)}
            >
              <p>{item}</p>
              <DownArrow />
            </a.div>
          )
      )}
    </>
  );
});

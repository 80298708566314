import { a } from "@react-spring/three";
import React, { memo, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { useFrame } from "react-three-fiber";
import {
  cameraMoveX,
  cameraMoveY,
  numSections,
  sectionScalePower,
} from "../constants/misc";
import { Context } from "./MainCanvas";
import Intro from "./sections/intro/Intro";
import Photos from "./sections/photos/Photos";
import Postcards from "./sections/postcards/Postcards";
import TShirts from "./sections/t-shirts/TShirts";

export default memo(() => {
  const { x, activeSectionSmooth: activeIndex, subtleMotion } = useContext(
    Context
  );

  useFrame(({ gl, scene, camera }) => {
    gl && gl.render(scene, camera);
  }, 3);

  const isBigScreen = useMediaQuery({ query: "(min-device-width: 400px)" });

  return (
    <a.group
      rotation-x={subtleMotion.value.to((x, y) => (y * 2 - 1) * cameraMoveY)}
      rotation-y={subtleMotion.value.to((x, y) => (x * 2 - 1) * cameraMoveX)}
      scale={isBigScreen ? [1, 1, 1] : [1, 1, 1]}
    >
      <a.group
        scale-x={activeIndex
          .to([0, numSections - 1], [0, -sectionScalePower])
          .to((v: any) => Math.pow(sectionScalePower, v))}
        scale-y={activeIndex
          .to([0, numSections - 1], [0, -sectionScalePower])
          .to((v: any) => Math.pow(sectionScalePower, v))}
        scale-z={activeIndex
          .to([0, numSections - 1], [0, -sectionScalePower])
          .to((v: any) => Math.pow(sectionScalePower, v))}
        rotation-y={x}
      >
        <Intro />
        <Postcards />
        <TShirts />
        <Photos />
      </a.group>
    </a.group>
  );
});

import { a, useSpring, to } from "@react-spring/three";
import React, { useContext, useMemo } from "react";
import { Object3DNode, useThree } from "react-three-fiber";
import { CanvasTexture, Object3D } from "three";
import { useStore } from "../store";
import { SectionContext } from "./Section";
import * as easings from "d3-ease";

function drawCanvas(innerText: string, outerText: string, renderer: any) {
  const canvas = document.createElement("canvas");
  canvas.width = canvas.height = 2048;

  const context = canvas.getContext("2d")!;

  context.strokeStyle = "white";
  context.fillStyle = "white";
  const fontSize = 80;
  context.font = `${fontSize}px HelveticaNeue`;

  for (let i = 0; i < 3; i++) {
    drawCircularText(
      context,

      innerText,
      canvas.width / 2,
      canvas.height / 2,
      canvas.width / 2 + 850,
      -60 + i * 120,
      "center",
      false,
      false,
      -4
    );

    drawCircularText(
      context,
      outerText,
      canvas.width / 2,
      canvas.height / 2,
      canvas.width / 2 + 820,
      -60 + i * 120,
      "center",
      true,
      false,
      -4
    );
  }

  context.beginPath();
  context.arc(
    canvas.width / 2,
    canvas.height / 2,
    canvas.width / 2 - 100,
    0,
    2 * Math.PI,
    false
  );
  context.lineWidth = 10;
  context.strokeStyle = "white";
  context.stroke();
  // context.fill();

  const texture = new CanvasTexture(canvas);
  texture.anisotropy = renderer.capabilities.getMaxAnisotropy();

  return texture;
}

function drawCircularText(
  context: any,
  text: string,
  x: number,
  y: number,
  diameter: number,
  startAngle: number,
  align: string,
  textInside: boolean,
  inwardFacing: boolean,
  kerning: number
) {
  align = align.toLowerCase();
  var clockwise = align === "right" ? 1 : -1; // draw clockwise for aligned right. Else Anticlockwise
  startAngle = startAngle * (Math.PI / 180); // convert to radians

  var textHeight = 90;

  // in cases where we are drawing outside diameter,
  // expand diameter to handle it
  if (!textInside) diameter += textHeight * 2;

  // canvas.width = diameter;
  // canvas.height = diameter;
  // omit next line for transparent background

  // Reverse letters for align Left inward, align right outward
  // and align center inward.
  if (
    (["left", "center"].indexOf(align) > -1 && inwardFacing) ||
    (align === "right" && !inwardFacing)
  )
    text = text.split("").reverse().join("");

  // Setup letters and positioning
  context.save();
  context.translate(x, y); // Move to center
  startAngle += Math.PI * (inwardFacing ? 0 : 1); // Rotate 180 if outward
  context.textBaseline = "middle"; // Ensure we draw in exact center
  context.textAlign = "center"; // Ensure we draw in exact center

  // rotate 50% of total angle for center alignment
  if (align === "center") {
    for (var j = 0; j < text.length; j++) {
      var charWid = context.measureText(text[j]).width;
      startAngle +=
        ((charWid + (j === text.length - 1 ? 0 : kerning)) /
          (diameter / 2 - textHeight) /
          2) *
        -clockwise;
    }
  }

  // Phew... now rotate into final start position
  context.rotate(startAngle);

  // Now for the fun bit: draw, rotate, and repeat
  for (var j = 0; j < text.length; j++) {
    var charWid = context.measureText(text[j]).width; // half letter
    // rotate half letter
    context.rotate((charWid / 2 / (diameter / 2 - textHeight)) * clockwise);
    // draw the character at "top" or "bottom"
    // depending on inward or outward facing
    context.fillText(
      text[j],
      0,
      (inwardFacing ? 1 : -1) * (0 - diameter / 2 + textHeight / 2)
    );

    context.rotate(
      ((charWid / 2 + kerning) / (diameter / 2 - textHeight)) * clockwise
    ); // rotate half letter
  }

  context.restore();
}

type Props = {
  radius: number;
  innerText: string;
  outerText: string;
} & Object3DNode<Object3D, typeof Object3D>;

export default ({ radius, innerText, outerText, ...props }: Props) => {
  const { isViewingItem, activeSection } = useStore();

  const { index, activeAmount } = useContext(SectionContext);
  const { gl } = useThree();

  const texture = useMemo(() => {
    return drawCanvas(innerText, outerText, gl);
  }, [innerText, outerText]);

  const opacity = useSpring({
    value: !isViewingItem ? 1 : 0,
    config: {
      duration: 500,
      easing: easings.easeCubicInOut,
    },
    native: true,
  });

  return (
    <group position={[0, 0.2, 0]} rotation={[-Math.PI / 2, 0, 0]}>
      <mesh>
        <ringBufferGeometry args={[radius * 0.9, radius * 1.1, 32]} />
        <a.meshBasicMaterial
          map={texture}
          transparent
          alphaTest={0.5}
          //@ts-ignore
          opacity={to(
            [activeAmount, opacity.value],
            (a: number, b: number) => a * b
          )}
        />
      </mesh>
    </group>
  );
};

import { a, useSpring } from "@react-spring/web";
import * as easings from "d3-ease";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../store";
import TwoPartText from "./TwoPartText";

type Props = {};

export default observer(({}: Props) => {
  const {
    isViewingItem,
    activeSection,
    activeShirtId,
    isShirtTextVisible,
    shirts,
    currentLanguage,
  } = useStore();

  const { t } = useTranslation();

  const shirt = shirts[activeShirtId];

  const opacity = useSpring({
    value: activeSection === 2 && !isViewingItem && isShirtTextVisible ? 1 : 0,
    config: {
      duration: 500,
      easing: easings.easeCubicInOut,
    },
    native: true,
  });

  const topText = shirt.title[currentLanguage];
  const bottomText = shirt.isReleased
    ? `${t("by")} ${shirt.designer}\n${shirt.description[currentLanguage]}`
    : t("comingSoon");

  return (
    <TwoPartText
      opacity={opacity.value}
      topText={topText}
      bottomText={bottomText}
    />
  );
});

import { a, useSpring } from "@react-spring/web";
import * as easings from "d3-ease";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../store";
import TwoPartText from "./TwoPartText";

type Props = {
  sectionIndex: number;
  topTextKey: string;
  bottomTextKey: string;
};

export default observer(
  ({ sectionIndex, topTextKey, bottomTextKey }: Props) => {
    const { isViewingItem, activeSection } = useStore();

    const { t } = useTranslation();

    const opacity = useSpring({
      value: activeSection === sectionIndex && !isViewingItem ? 1 : 0,
      config: {
        duration: 500,
        easing: easings.easeCubicInOut,
      },
      native: true,
    });

    return (
      <TwoPartText
        opacity={opacity.value}
        topText={t(topTextKey)}
        bottomText={t(bottomTextKey)}
      />
    );
  }
);

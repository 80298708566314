import { a } from "@react-spring/three";
import { useTexture } from "@react-three/drei";
import React, { Suspense, useContext } from "react";
import * as THREE from "three";
import Galaxy from "../../Galaxy";
import Section, { SectionContext } from "../../Section";

const Logo = () => {
  const texture = useTexture("images/SVD-10-logo-white.png");
  const { activeAmount } = useContext(SectionContext);

  return (
    <mesh scale={[3, 3, 3]}>
      <planeBufferGeometry />
      <a.meshBasicMaterial
        side={THREE.DoubleSide}
        map={texture}
        transparent
        opacity={activeAmount}
        alphaTest={0.4}
      />
    </mesh>
  );
};

export default () => {
  return (
    <Suspense fallback={null}>
      <Section index={0} noRing>
        <Galaxy />
        <Logo />
      </Section>
    </Suspense>
  );
};

export function scale(
  number: number,
  fromLeft: number,
  fromRight: number,
  toLeft: number,
  toRight: number
) {
  return (
    toLeft + ((number - fromLeft) / (fromRight - fromLeft)) * (toRight - toLeft)
  );
}

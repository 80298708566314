import { config, useSpring } from "@react-spring/three";
import * as easings from "d3-ease";
import { useEffect, useRef, useState } from "react";
import { useThree } from "react-three-fiber";
import { Object3D, Vector3 } from "three";

export default function useZoom(
  isViewing: boolean,
  zoomDistance: number,
  scale: number
) {
  const zoomRef = useRef<Object3D>();
  const { scene, camera } = useThree();

  const [originalParent, setOriginalParent] = useState<Object3D | null>(null);

  const [position, setPosition] = useSpring(() => ({
    value: [0, 0, 0],
    config: {
      duration: 500,
      easing: easings.easeCubicInOut,
    },
    native: true,
  }));

  const [rotation, setRotation] = useSpring(() => ({
    value: [0, 0, 0, 0],
    config: config.slow,
    native: true,
  }));

  useEffect(() => {
    if (zoomRef.current) {
      if (isViewing) {
        setOriginalParent(zoomRef.current.parent!);
        scene.attach(zoomRef.current);

        var camForward = new Vector3();
        camera.getWorldDirection(camForward);

        const newPosition = camera.position
          .clone()
          .add(camForward.clone().multiplyScalar(zoomDistance));

        const newQuaternion = camera.quaternion.clone();

        setPosition({
          from: {
            value: zoomRef.current.position.toArray(),
          },
          value: newPosition.toArray(),
          reset: true,
        });

        setRotation({
          from: {
            value: zoomRef.current.quaternion.toArray(),
          },
          value: newQuaternion.toArray(),
          reset: true,
        });
      } else if (originalParent) {
        originalParent.attach(zoomRef.current);
        zoomRef.current.scale.set(1, 1, 1);

        setPosition({
          from: {
            value: zoomRef.current.position.toArray(),
          },
          value: [0, 0, 0],
          reset: true,
        });

        setRotation({
          from: {
            value: zoomRef.current.quaternion.toArray(),
          },
          value: [0, 0, 0, 1],
          reset: true,
        });
      }
    }
  }, [isViewing]);

  return { zoomRef, position, rotation, setPosition, setRotation };
}

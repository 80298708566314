import axios from "axios";
import { createHashHistory, HashHistory, State } from "history";
import i18next, { i18n } from "i18next";
import range from "lodash/range";
import throttle from "lodash/throttle";
import { makeAutoObservable, when } from "mobx";
import { createContext, useContext } from "react";
import { initReactI18next } from "react-i18next";
import { Cache } from "three";
import clamp from "lodash/clamp";

const sectionPaths = ["/", "/past", "/present", "/future"];

type Languages = {
  en: string;
  es: string;
  de: string;
  it: string;
  fr: string;
  pt: string;
  [lang: string]: string;
};

export type Shirt = {
  isReleased: boolean;
  isAvailable: boolean;
  shirtImageFilename: string;
  backgroundImageFilename: string;
  designer: string;
  title: Languages;
  description: Languages;
  storeUrl: Languages;
};

export type Postcard = {
  imageFilename: string;
  backgroundImageFilename: string;
  year: number;
  title: Languages;
  description: Languages;
};

export type Photo = {
  filename: string;
};

class Store {
  i18n: i18n;
  shirts!: Shirt[];
  postcards!: Postcard[];
  photos!: Photo[];
  siteText: any;

  viewedPostcardId = -1;
  lastViewedPostcardId = -1;
  activeSection = 0;
  viewedShirtId = -1;
  lastViewedShirtId = -1;
  activeShirtId = 0;
  isShirtTextVisible = true;

  isAnimating = false;
  isDragging = false;

  snapToSection: any;
  snapToShirt: any;

  numCardsToRender = 1;
  numRingsToRender = 1;

  currentLanguage = "en";

  history: HashHistory<State>;
  isGalaxyReady = false;

  postcardTimer: any;
  ringTimer: any;

  isFirstPostcardView = true;

  constructor() {
    makeAutoObservable(this);
    this.i18n = i18next.use(initReactI18next);

    Cache.enabled = true;

    this.i18n.on(
      "languageChanged",
      (language) => (this.currentLanguage = language)
    );

    this.history = createHashHistory();
  }

  async init() {
    await this.loadData();

    await this.i18n.init({
      resources: this.siteText,
      lng: "en",
      interpolation: {
        escapeValue: false,
      },
      defaultNS: "common",
    });

    const language = (new URLSearchParams(window.location.search)).get("lang") || "en"

    if (["en", "es", "de", "fr", "it", "pt"].includes(language))
      this.i18n.changeLanguage(language);

    if ("ResizeObserver" in window === false) {
      const module = await import("@juggle/resize-observer");
      window.ResizeObserver = module.ResizeObserver;
    }

    this.setCursor("grab");

    this.postcardTimer = setInterval(() => {
      this.numCardsToRender++;

      if (this.numCardsToRender >= this.postcards.length) {
        clearInterval(this.postcardTimer);
      }
    }, 50);

    when(
      () => this.isInitialised,
      () => {
        this.processPath();
      }
    );
  }

  setGalaxyReady() {
    this.isGalaxyReady = true;
  }

  get isInitialised() {
    return (
      this.postcards &&
      this.numCardsToRender >= this.postcards.length &&
      this.isGalaxyReady === true
    );
  }

  processPath() {
    let matches = this.history.location.pathname.match(
      /\/past\/postcard\/(\d+)/
    );
    if (matches) {
      const postcardId = parseInt(matches[1]);

      this.setActiveSection(1);

      setTimeout(() => {
        this.setViewedPostcard(postcardId);
      }, 1000);
    }

    matches = this.history.location.pathname.match(/\/present\/t-shirt\/(\d)/);
    if (matches) {
      const shirtId = parseInt(matches[1]);

      this.setActiveSection(2);
      this.setActiveShirt(shirtId);

      setTimeout(() => {
        this.setViewedShirt(shirtId);
      }, 1000);
    }

    matches = this.history.location.pathname.match(/\/past/);
    if (matches) {
      this.setActiveSection(1);
    }

    matches = this.history.location.pathname.match(/\/present/);
    if (matches) {
      this.setActiveSection(2);
    }

    matches = this.history.location.pathname.match(/\/future/);
    if (matches) {
      this.setActiveSection(3);
    }
  }

  async loadData() {
    const response = await axios.get("/data.json");

    this.processShirts(response.data[0].slice(1));
    this.processPostcards(response.data[1].slice(1));
    this.processPhotos(response.data[2]);
    this.processSiteText(response.data[3]);
  }

  processShirts(rows: any[][]) {
    this.shirts = rows.map((row) => ({
      isReleased: row[0],
      isAvailable: row[1],
      shirtImageFilename: row[2],
      backgroundImageFilename: row[3],
      designer: row[4],
      title: {
        en: row[5],
        es: row[6],
        de: row[7],
        fr: row[8],
        it: row[9],
        pt: row[10],
      },
      description: {
        en: row[11],
        es: row[12],
        de: row[13],
        fr: row[14],
        it: row[15],
        pt: row[16],
      },
      storeUrl: {
        en: row[17],
        es: row[18],
        de: row[19],
        fr: row[20],
        it: row[21],
        pt: row[22],
      },
    }));
  }

  processPostcards(rows: any[][]) {
    this.postcards = rows.map((row) => ({
      imageFilename: row[0],
      backgroundImageFilename: row[1],
      year: row[2],
      title: {
        en: row[3],
        es: row[4],
        de: row[5],
        fr: row[6],
        it: row[7],
        pt: row[8],
      },
      description: {
        en: row[9],
        es: row[10],
        de: row[11],
        fr: row[12],
        it: row[13],
        pt: row[14],
      },
    }));
  }

  processPhotos(rows: any[][]) {
    this.photos = rows.map((row) => ({
      filename: row[0],
    }));
  }

  processSiteText(rows: any[][]) {
    this.siteText = {
      en: { common: {} },
      es: { common: {} },
      de: { common: {} },
      it: { common: {} },
      fr: { common: {} },
      pt: { common: {} },
    };

    rows.forEach((row) => {
      const key = row[0];
      this.siteText.en.common[key] = row[1];
      this.siteText.es.common[key] = row[2];
      this.siteText.de.common[key] = row[3];
      this.siteText.fr.common[key] = row[4];
      this.siteText.it.common[key] = row[5];
      this.siteText.pt.common[key] = row[6];
    });
  }

  setActiveSection(index: number) {
    if (index < 0 || index >= 4 || this.isViewingItem) return;

    this.setActiveSectionThrottled(index);
  }

  setActiveSectionThrottled = throttle(
    (index: number) => {
      this.activeSection = index;

      this.snapToSection(index);

      if (index === 0 || index === 3) this.setCursor("grab");

      if (index !== 1) this.setViewedPostcard(-1);
      if (index === 2) {
        this.setActiveShirt(this.mostRecentShirtId);
        this.setShirtTextVisible(true);
      } else this.setViewedShirt(-1);

      this.setAnimating(true);

      this.history.push(sectionPaths[index]);
    },
    700,
    { leading: true, trailing: false }
  );

  changeSection(change: number) {
    this.setActiveSection(this.activeSection + change);
  }

  setAnimating(animating: boolean) {
    this.isAnimating = animating;
  }

  setViewedShirt(id: number) {
    if (this.viewedShirtId === id || (store.isViewingItem && id >= 0) || store.activeSection !== 2) return;

    this.viewedShirtId = id;

    if (id >= 0) {
      this.lastViewedShirtId = id;
      this.history.push(`/present/t-shirt/${id}`);
      this.setCursor("default");
    } else {
      this.history.back();
      this.setShirtTextVisible(true);
    }
  }

  setActiveShirt(id: number) {
    if (id !== this.activeShirtId) {
      this.activeShirtId = id;
      this.setShirtTextVisible(true);
      this.snapToShirt(id);
    }
  }

  setShirtTextVisible(visible: boolean) {
    this.isShirtTextVisible = visible;
  }

  setViewedPostcard(id: number) {
    if (this.viewedPostcardId === id || (store.isViewingItem && id >= 0) || store.activeSection !== 1) return;

    this.viewedPostcardId = id;

    if (id >= 0) {
      this.lastViewedPostcardId = id;
      this.history.push(`/past/postcard/${id}`);
      this.setCursor("alias");
    } else {
      this.history.back();
    }
  }

  setFirstPostcardViewed() {
    this.isFirstPostcardView = false;
  }

  get isViewingItem() {
    return this.viewedShirtId !== -1 || this.viewedPostcardId !== -1;
  }

  get mostRecentShirtId() {
    return clamp(
      range(this.shirts.length).find((id) => !store.shirts[id].isReleased)! - 1,
      0,
      this.shirts.length - 1
    );
  }

  setCursor(cursor: string) {
    document.body.style.cursor = cursor;
  }

  setDragging(dragging: boolean) {
    this.isDragging = dragging;

    if (dragging)
      document.body.style.cursor === "grab" && store.setCursor("grabbing");
    else document.body.style.cursor === "grabbing" && store.setCursor("grab");
  }
}

export const store = new Store();

const context = createContext<Store>(store);

export const useStore = () => {
  return useContext(context);
};

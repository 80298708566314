import { useSpring } from "@react-spring/three";
import { useTexture } from "@react-three/drei";
import * as easings from "d3-ease";
import { memo, useContext, useEffect, useMemo, useState } from "react";
import { useFrame } from "react-three-fiber";
import {
  Mesh,
  OrthographicCamera,
  PlaneBufferGeometry,
  RepeatWrapping,
  Scene,
  ShaderMaterial,
  Texture,
  TextureLoader,
  Vector2,
} from "three";
import { Context } from "./MainCanvas";

const vertexShader = `
varying vec2 v_uv;
        
void main() {
  v_uv = uv;
  gl_Position = vec4( position, 1.0 );    
}
`;

const fragmentShader = `
varying vec2 v_uv;
uniform vec2 resolution;
uniform vec4 params;
uniform float time;
uniform float opacity;
uniform sampler2D inputTexture;
uniform sampler2D warpTexture;

const vec4 backgroundColor = vec4(0.0, 0.10858, 0.67451, 1.0);

vec2 perlin(vec2 uv, float f0, float fmul, float v0, float vmul){
  vec2 val = vec2(0.0, 0.0);
  float frq = f0;
  float wei = v0;

  for (float i=0.0; i<7.0; i+= 1.0) {
      val += wei * texture2D(warpTexture, uv * frq + time * 0.001 + params.xy).xy;
      frq *= fmul;
      wei *= vmul;
  }
 
  return val;
}

vec2 pdef(vec2 uv) {
  return perlin(uv, 0.05, 1.6, 0.3, 0.8);
}

vec2 warped(vec2 uv) {
  return uv + (pdef(uv+pdef(uv)) - 0.5) * 0.5;
}

void main()
{
  vec2 uv = v_uv * resolution + (1.0 - resolution) * 0.5;

  vec4 color = texture2D(inputTexture, warped(uv));
  gl_FragColor = mix(color, backgroundColor, (1.0 - opacity * smoothstep(0.0, 0.1, color.r)) ); 
}

`;

type Props = { visible: boolean; imageUrl: string };

const initialParams = [0.2, 0.5, 1, 1.6];

export default memo(({ visible, imageUrl }: Props) => {
  const { subtleMotion } = useContext(Context);
  const [inputTexture, setInputTexture] = useState<Texture | null>(null);

  const warpTexture = useTexture("images/stone.jpg");
  warpTexture.wrapS = warpTexture.wrapT = RepeatWrapping;

  useEffect(() => {
    setInputTexture(null);
    const loader = new TextureLoader();
    loader.load(imageUrl, (texture) => setInputTexture(texture));
  }, [imageUrl]);

  const opacity = useSpring({
    value: visible && inputTexture ? 1 : 0,
    config: {
      duration: 500,
      easing: easings.easeCubicInOut,
    },
    native: true,
  });

  const { camera, scene, uniforms } = useMemo(() => {
    const scene = new Scene();

    const camera = new OrthographicCamera(-1, 1, 1, -1, 0, 1);

    const material = new ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms: {
        time: { value: 21341.54354 },
        opacity: { value: 0 },
        resolution: {
          value: [1, 1],
        },
        params: {
          value: initialParams,
        },
        inputTexture: {
          value: inputTexture,
        },
        warpTexture: {
          value: warpTexture,
        },
      },
      depthWrite: false,
      depthTest: false,
      lights: false,
      transparent: false,
    });

    const quad = new Mesh(new PlaneBufferGeometry(2, 2, 1, 1), material);
    scene.add(quad);

    return {
      scene,
      camera,
      uniforms: material.uniforms,
    };
  }, []);

  useFrame(({ gl }) => {
    scene.visible = opacity.value.get() > 0;

    if (!gl || !scene.visible) return;

    uniforms.opacity.value = opacity.value.get();

    let size = new Vector2();
    gl.getSize(size);
    uniforms.resolution.value = [size.x / size.y, 1];

    uniforms.time.value += 0.02;

    uniforms.inputTexture.value = inputTexture;

    const mouseMovement = subtleMotion.value.get();
    uniforms.params.value = [
      initialParams[0] + mouseMovement[0] * 0.01,
      initialParams[1] + mouseMovement[1] * 0.01,
      initialParams[2],
      initialParams[3],
    ];
    gl.render(scene, camera);
  }, 0);

  return (
    <primitive object={scene}>
      <primitive object={camera}></primitive>
    </primitive>
  );
});

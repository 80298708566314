import { ResizeObserver } from "@juggle/resize-observer";
import { config, SpringValue, useSpring } from "@react-spring/three";
import { Preload, Stats, useDetectGPU } from "@react-three/drei";
import { observer } from "mobx-react";
import React, { createContext, Suspense } from "react";
import { Canvas } from "react-three-fiber";
import { useMove } from "react-use-gesture";
import { State } from "react-use-gesture/dist/types";
import useGestures from "../helpers/useGestures";
import { useStore } from "../store";
import NavArrows from "./NavArrows";
import PostcardZoom from "./PostcardZoom";
import Scene from "./Scene";
import Gallery from "./sections/photos/gallery/Gallery";
import SectionText from "./SectionText";
import ShirtText from "./ShirtText";
import ShirtZoom from "./ShirtZoom";

export type NavigationValues = {
  activeSectionSmooth: SpringValue<number>;
  x: SpringValue<number>;
  subtleMotion: { value: SpringValue<number[]> };
};

export const Context = createContext<NavigationValues>({
  x: new SpringValue(),
  subtleMotion: { value: new SpringValue() },
  activeSectionSmooth: new SpringValue(),
});

export default observer(() => {
  const { x, sectionSmooth, snapToSection, snapToShirt } = useGestures({
    domTarget: window,
  });
  const store = useStore();

  store.snapToSection = snapToSection;
  store.snapToShirt = snapToShirt;

  function onBackgroundClick() {
    store.setViewedPostcard(-1);
    store.setViewedShirt(-1);
  }

  const [subtleMotion, setSubtleMotion] = useSpring(() => ({
    value: [0, 0],
    config: config.slow,
    native: true,
  }));

  function handleMouseMove({ xy: [x, y], vxvy: [vx, vy] }: State["move"]) {
    setSubtleMotion({
      value: [x / window.innerWidth, y / window.innerHeight],
    });
  }

  useMove(handleMouseMove, { domTarget: window });

  const gpuTier = useDetectGPU();

  return (
    <Context.Provider
      value={{
        activeSectionSmooth: sectionSmooth,
        x,
        subtleMotion,
      }}
    >
      <>
        {/* <Helmet>
          <title>SVD</title>
        </Helmet> */}
        <Gallery />
        <Canvas
          concurrent
          gl={{ antialias: !gpuTier?.isMobile }}
          pixelRatio={window.devicePixelRatio}
          onPointerMissed={onBackgroundClick}
          className="mainCanvas"
          resize={{ polyfill: ResizeObserver }}
          camera={{
            position: [0, 4, 17],
            fov: 40,
            near: 1,
            far: 40,
          }}
          onMouseDown={() => store.setDragging(true)}
          onMouseUp={() => store.setDragging(false)}
        >
          <Context.Provider
            value={{
              activeSectionSmooth: sectionSmooth,
              x,
              subtleMotion,
            }}
          >
            <Scene />
            <Preload all />
            {/* <Stats showPanel={0} /> */}
          </Context.Provider>
        </Canvas>
        <SectionText
          sectionIndex={0}
          topTextKey="introTop"
          bottomTextKey="introBottom"
        />
        <PostcardZoom />
        <ShirtText />
        <ShirtZoom />
        <SectionText
          sectionIndex={3}
          topTextKey="futureTop"
          bottomTextKey="futureBottom"
        />
        <NavArrows />
      </>
    </Context.Provider>
  );
});

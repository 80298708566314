import { to } from "@react-spring/three";
import { a } from "@react-spring/web";
import React, { memo, useContext } from "react";
import useMeasure from "react-use-measure";
import { Photo as PhotoType, useStore } from "../../../../store";
import { Context } from "../../../MainCanvas";
import { getActiveAmount } from "../../../Section";

type PhotoProps = {
  photo: PhotoType;
  index: number;
  size: number[];
  totalWidth: number;
};

const aspectRatio = 0.7;

function getImageLeft(
  x: number,
  containerWidth: number,
  xOffset: number,
  width: number
) {
  const wrappedToCircle = (x + Math.PI * 2 * 1000) % (Math.PI * 2);
  const normalized = wrappedToCircle / (Math.PI * 2);
  return (
    ((normalized * containerWidth + xOffset + width) % containerWidth) - width
  );
}

const Photo = ({ photo, index, size, totalWidth }: PhotoProps) => {
  const { x, subtleMotion } = useContext(Context);

  return (
    <a.img
      className="galleryPhoto"
      style={{
        width: size[0],
        height: size[1],
        //@ts-ignore
        translateX: to([x, subtleMotion.value], (x: number, [sx]: any) =>
          getImageLeft(x + sx * 0.2, totalWidth, index * size[0], size[0])
        ),
      }}
      src={`images/photos/${photo.filename}`}
    />
  );
};

export default memo(() => {
  const { photos } = useStore();
  const { activeSectionSmooth } = useContext(Context);

  const [ref, bounds] = useMeasure();

  const imageWidth = bounds.height * aspectRatio;
  const totalWidth = imageWidth * photos.length;

  return (
    <a.div
      className="galleryContainer"
      ref={ref}
      style={{
        opacity: activeSectionSmooth.to((v: any) =>
          getActiveAmount(v, 3)
        ) as any,
      }}
    >
      {photos.map((photo, i) => (
        <Photo
          key={i}
          index={i}
          photo={photo}
          size={[imageWidth, bounds.height]}
          totalWidth={totalWidth}
        />
      ))}
    </a.div>
  );
});

export const dragYSensitivity = 0.7;
export const scrollYSensitivity = 0.001;
export const minSwipeVelocity = 0.1;
export const cameraMoveX = -0.1;
export const cameraMoveY = 0.1;

export const numSections = 4;
export const sectionScalePower = 3;

export const numParticles = 128;

export const numShirts = 10;

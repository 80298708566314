import { config, useSpring } from "@react-spring/core";
import { useCallback } from "react";
import { useGesture } from "react-use-gesture";
import { minSwipeVelocity, numShirts } from "../constants/misc";
import { useStore } from "../store";

function getShirtForAngle(x: number) {
  const anglePerShirt = (Math.PI * 2) / numShirts;
  const shirtIndex = Math.round((x + (2 * Math.PI) / 3) / anglePerShirt);

  return (shirtIndex + numShirts * 1000) % numShirts;
}

function getAngleForShirt(shirtIndex: number) {
  const anglePerShirt = (Math.PI * 2) / numShirts;
  return shirtIndex * anglePerShirt - (2 * Math.PI) / 3;
}

export default function useGestures(props: any) {
  const store = useStore();

  const [{ x }, setX] = useSpring(() => ({
    x: 0,
    config: config.slow,

    onRest: () => {
      if (store.activeSection === 2) {
        let shirtIndex = getShirtForAngle(x.get());
        store.setActiveShirt(shirtIndex);
      }
    },
    native: true,
  }));

  const [{ sectionSmooth }, setSectionSmooth] = useSpring(() => ({
    sectionSmooth: 0,
    config: config.slow,
    onStart: () => {
      store.setAnimating(true);
    },
    onRest: () => {
      const section = Math.round(sectionSmooth.get());
      setSectionSmooth({ sectionSmooth: section });
      store.setAnimating(false);
    },
    native: true,
  }));

  const snapToSection = useCallback((sectionIndex: number) => {
    setSectionSmooth({
      sectionSmooth: sectionIndex,
    });

    const third = (Math.PI * 2) / 3;
    const newSectionX = sectionIndex * third;
    setX({
      x: newSectionX + Math.floor(x.get() / (Math.PI * 2)) * (Math.PI * 2),
    });
  }, []);

  const snapToShirt = useCallback((shirtId: number) => {
    const newX =
      (getAngleForShirt(shirtId) + Math.PI * 2 * 1000) % (Math.PI * 2);
    const diff = newX - ((x.get() + Math.PI * 2 * 1000) % (Math.PI * 2));

    setX({
      x: x.get() + diff,
    });
  }, []);

  useGesture(
    {
      onDrag: ({ vxvy: [vx, vy] }) => {
        if (!store.isViewingItem) {
          let newX = x.get() + vx;

          setX({
            x: newX,
          });
        }

        store.setShirtTextVisible(false);
      },
      onDragEnd: ({ vxvy: [vx, vy] }) => {
        let change = 0;
        if (vy > minSwipeVelocity) change = -1;
        else if (vy < -minSwipeVelocity) change = 1;

        if (change !== 0) {
          store.changeSection(change);
        } else {
          if (store.activeSection === 2 && Math.abs(vx) < 1) {
            let shirtIndex = getShirtForAngle(x.get());
            store.setActiveShirt(shirtIndex);
            snapToShirt(shirtIndex);
          }
        }
      },
      onMouseUp: () => {
        store.setShirtTextVisible(true);
      },
      onWheel: ({ vxvy: [vx, vy] }) => {
        if (store.isViewingItem) return;

        let change = 0;
        if (vy > 1) change = 1;
        else if (vy < -1) change = -1;

        if (change !== 0) {
          store.changeSection(change);
        }
      },
    },
    {
      ...props,
      drag: {
        lockDirection: true,
        // experimental_preventWindowScrollY: true,
        filterTaps: true, // prevent click when dragging
      },
    }
  );

  return { x, sectionSmooth, snapToSection, snapToShirt };
}

import { observer } from "mobx-react";
import React, { Suspense } from "react";
import { useStore } from "../../../store";
import DynamicBackground from "../../DynamicBackground";
import Section from "../../Section";
import TShirtRack from "./TShirtRack";

export default observer(() => {
  const { lastViewedShirtId, viewedShirtId, shirts } = useStore();

  const lastViewedShirt =
    lastViewedShirtId !== -1 ? shirts[lastViewedShirtId] : undefined;

  return (
    <Suspense fallback={null}>
      <Section index={2}>
        <DynamicBackground
          visible={viewedShirtId !== -1}
          imageUrl={`images/shirts/${lastViewedShirt?.backgroundImageFilename}`}
        />
        <TShirtRack />
      </Section>
    </Suspense>
  );
});

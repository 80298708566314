import { observer } from "mobx-react";
import React, { Suspense } from "react";
import { useStore } from "../../../store";
import DynamicBackground from "../../DynamicBackground";
import Section from "../../Section";
import PostcardRack from "./PostcardRack";

export default observer(() => {
  const { lastViewedPostcardId, viewedPostcardId, postcards } = useStore();

  const lastViewedPostcard =
    lastViewedPostcardId !== -1 ? postcards[lastViewedPostcardId] : undefined;

  return (
    <Section index={1}>
      <Suspense fallback={null}>
        <DynamicBackground
          visible={viewedPostcardId !== -1}
          imageUrl={`images/postcards/${lastViewedPostcard?.backgroundImageFilename}`}
        />
      </Suspense>
      <PostcardRack />
    </Section>
  );
});

import { a, SpringValue } from "@react-spring/web";
import { observer } from "mobx-react";

type Props = {
  opacity: SpringValue<number>;
  topText: string;
  bottomText: string;
};

export default observer(({ opacity, topText, bottomText }: Props) => {
  const bottomTextParts = bottomText.split("\n");
  return (
    <a.div
      className="topBottomContainer"
      style={{
        //@ts-ignore
        opacity: opacity,
      }}
    >
      <div>
        <p>{topText}</p>
        <div>
          {bottomTextParts.map((part, i) => (
            <p key={i}>{part}</p>
          ))}
        </div>
      </div>
    </a.div>
  );
});

import { a, SpringValue, to, useSpring } from "@react-spring/three";
import { useTexture } from "@react-three/drei";
import React, {
  memo,
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useThree } from "react-three-fiber";
import { BackSide, CanvasTexture, Euler, FrontSide, Quaternion } from "three";
import { CanvasTextWrapper } from "../../../helpers/TextWrapper";
import useCursor from "../../../helpers/useCursor";
import useZoom from "../../../helpers/useZoom";
import { useStore } from "../../../store";
import { SectionContext } from "../../Section";
import { useMediaQuery } from "react-responsive";
import * as easings from "d3-ease";
import { Context } from "../../MainCanvas";
import { cameraMoveY, cameraMoveX } from "../../../constants/misc";

const cardWidthRatio = 0.666;
const cardHeight = 0.8;
const cardWidth = cardHeight * cardWidthRatio;

const logoImage = new Image();
logoImage.src = "images/SVD-10-logo-black.png";

function drawCanvas(
  renderer: any,
  year: string,
  titleText: string,
  descriptionText: string,
  bottomText: string,
  height: number
) {
  const canvas = document.createElement("canvas");

  const context = canvas.getContext("2d")!;
  context.canvas.height = height;
  context.canvas.width = canvas.height * cardWidthRatio;
  const scale = context.canvas.height / 2048;

  const padding = 60 * scale;

  context.fillStyle = "white";
  context.fillRect(0, 0, canvas.width, canvas.height);

  context.fillStyle = "black";

  context.textBaseline = "top";
  context.textAlign = "left";
  context.font = `${30 * scale}px Arial`;
  context.fillText(year, padding, padding);

  CanvasTextWrapper(canvas, "⠀" + titleText.toLocaleUpperCase(), {
    font: `${180 * scale}px HelveticaNeue`,
    paddingX: padding,
    paddingY: padding,
    justifyLines: true,
    lineHeight: scale === 1 ? 0.9 : 4,
  });

  CanvasTextWrapper(canvas, descriptionText, {
    font: `${50 * scale}px HelveticaNeue`,
    verticalAlign: "bottom",
    paddingX: padding,
    paddingY: 200 * scale,
    width: canvas.width * 0.5 + padding,
    lineHeight: scale === 1 ? 1.2 : 0.3,
  });

  CanvasTextWrapper(canvas, bottomText.toLocaleUpperCase(), {
    font: `${30 * scale}px HelveticaNeue`,
    verticalAlign: "bottom",
    paddingX: padding,
    paddingY: padding,
    justifyLines: true,
  });

  context.drawImage(
    logoImage,
    canvas.width - 250 * scale - padding,
    canvas.height - 250 * scale - (padding + 100 * scale),
    250 * scale,
    250 * scale
  );

  const texture = new CanvasTexture(canvas);
  texture.anisotropy = renderer.capabilities.getMaxAnisotropy();

  return texture;
}

type Props = {
  id: number;
  onClick: (id: number) => void;
  isViewing: boolean;
};

type PostcardMaterialProps = {
  imageFilename: string;
  id: number;
};

const PostcardMaterial = ({ id, imageFilename }: PostcardMaterialProps) => {
  const store = useStore();
  const { i18n, postcards, viewedPostcardId } = store;
  const { activeAmount, active } = useContext(SectionContext);

  const frontTexture = useTexture(`images/postcards/${imageFilename}`);

  const opacity = useSpring({
    value: viewedPostcardId === id || viewedPostcardId === -1 ? 1 : 0,
    config: {
      duration: 500,
      easing: easings.easeCubicInOut,
    },
    native: true,
  });

  return (
    <a.meshBasicMaterial
      side={FrontSide}
      transparent
      //@ts-ignore
      opacity={to([activeAmount, opacity.value], (a: any, b: any) => a * b)}
      map={frontTexture}
    />
  );
};

export default memo(({ id, onClick, isViewing }: Props) => {
  const { activeAmount, active } = useContext(SectionContext);
  const { subtleMotion } = useContext(Context);

  const store = useStore();
  const { i18n, postcards, viewedPostcardId } = store;

  const postcard = postcards[id];

  const { t } = useTranslation();

  const { camera } = useThree();

  const isBigScreen = useMediaQuery({ query: "(min-device-width: 400px)" });

  const { zoomRef, position, rotation, setPosition, setRotation } = useZoom(
    isViewing,
    isBigScreen ? 3.4 : 3.4,
    1.8
  );

  const [isFlipped, setFlipped] = useState(false);

  function handleClick(event: any) {
    event.stopPropagation();

    if (!isViewing) {
      onClick(id);
    } else {
      flip();
    }
  }

  function flip() {
    setFlipped((flipped) => !flipped);
    const newRotation = camera.quaternion.clone();

    if (!isFlipped) {
      const flipQuat = new Quaternion();
      flipQuat.setFromEuler(new Euler(0, Math.PI, 0));
      newRotation.multiply(flipQuat);
    }
    setRotation({ value: newRotation.toArray() });
  }

  const isViewed = viewedPostcardId === id;

  useEffect(() => {
    if (store.isFirstPostcardView && isViewed) {
      setTimeout(() => flip(), 1000);
      store.setFirstPostcardViewed();
    }
  }, [store.isFirstPostcardView, isViewed]);

  const opacity = useSpring({
    value: isViewed || viewedPostcardId === -1 ? 1 : 0,
    config: {
      duration: 500,
      easing: easings.easeCubicInOut,
    },

    native: true,
  });

  const zoom = useSpring({
    value: isViewed ? 1 : 0,
    config: {
      duration: 500,
      easing: easings.easeCubicInOut,
    },
    native: true,
  });

  const [handleEnter, handleExit] = useCursor(
    active,
    isViewed ? "alias" : "pointer"
  );

  const { gl } = useThree();

  const texture = useMemo(() => {
    const yearText = postcard.year + "";
    const titleText = postcard.title[i18n.language];
    const descriptionText = postcard.description[i18n.language];
    const bottomText = t("postcardBottom");
    return drawCanvas(
      gl,
      yearText,
      titleText,
      descriptionText,
      bottomText,
      isViewing ? 2048 : 256
    );
  }, [isViewing]);

  return (
    <a.group
      ref={zoomRef}
      position={position.value as any}
      quaternion={rotation.value as any}
      onPointerOver={handleEnter}
      onPointerOut={handleExit}
    >
      <a.group
        rotation-x={to([subtleMotion.value, zoom.value], (a: any, b: any) => {
          return (a[1] * 2 - 1) * 0.2 * b;
        })}
        rotation-y={to([subtleMotion.value, zoom.value], (a: any, b: any) => {
          return (a[0] * 2 - 1) * 0.2 * b;
        })}
      >
        <primitive object={texture} />
        <group scale={[cardWidth, cardHeight, 1]}>
          <mesh onClick={active ? handleClick : undefined}>
            <planeBufferGeometry />
            <Suspense
              fallback={
                <a.meshBasicMaterial
                  side={FrontSide}
                  transparent
                  //@ts-ignore
                  opacity={to(
                    [activeAmount, opacity.value],
                    (a: any, b: any) => a * b * 0.5
                  )}
                />
              }
            >
              <PostcardMaterial
                id={id}
                imageFilename={postcard.imageFilename}
              />
            </Suspense>
          </mesh>
          <mesh
            onClick={isViewing && active ? handleClick : undefined}
            scale-x={-1}
          >
            <planeBufferGeometry />
            <a.meshBasicMaterial
              side={BackSide}
              transparent
              opacity={to(
                [activeAmount, opacity.value],
                (a: any, b: any) => a * b
              )}
              map={texture}
            />
          </mesh>
        </group>
      </a.group>
    </a.group>
  );
});

import { a, to, useSpring } from "@react-spring/three";
import { useTexture } from "@react-three/drei";
import * as easings from "d3-ease";
import range from "lodash/range";
import { observer } from "mobx-react";
import React, { memo, useCallback, useContext, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { FrontSide, Material } from "three";
import { numShirts } from "../../../constants/misc";
import useCursor from "../../../helpers/useCursor";
import useZoom from "../../../helpers/useZoom";
import { useStore } from "../../../store";
import { Context } from "../../MainCanvas";
import { SectionContext } from "../../Section";

const ringRadius = 8.5;
const shirtHeight = 6;
const shirtWidth = shirtHeight * 0.9;

type Props = {
  id: number;
  onClick?: (id: number) => void;
  isViewing: boolean;
};

const TShirt = memo(({ id, isViewing, onClick }: Props) => {
  const { viewedShirtId } = useStore();

  const { activeAmount, active } = useContext(SectionContext);
  const { subtleMotion } = useContext(Context);

  const material = useRef<Material>();

  const { shirts } = useStore();
  const shirt = shirts[id];

  const texture = useTexture(`images/shirts/${shirt.shirtImageFilename}`);

  const isBigScreen = useMediaQuery({ query: "(min-device-width: 400px)" });

  const { zoomRef, position, rotation, setPosition, setRotation } = useZoom(
    isViewing,
    isBigScreen ? 20 : 22,
    1.5
  );

  const opacity = useSpring({
    value: viewedShirtId === id || viewedShirtId === -1 ? 1 : 0,
    config: {
      duration: 500,
      easing: easings.easeCubicInOut,
    },
    native: true,
  });

  const zoom = useSpring({
    value: viewedShirtId === id ? 1 : 0,
    config: {
      duration: 500,
      easing: easings.easeCubicInOut,
    },
    native: true,
  });

  const [handleEnter, handleExit] = useCursor(active && viewedShirtId === -1);

  function handleClick() {
    (viewedShirtId === id || viewedShirtId === -1) && onClick && onClick(id);
  }

  return (
    <a.group
      ref={zoomRef}
      position={position.value as any}
      quaternion={rotation.value as any}
      onPointerOver={handleEnter}
      onPointerOut={handleExit}
    >
      <a.group
        rotation-x={to([subtleMotion.value, zoom.value], (a: any, b: any) => {
          return (a[1] * 2 - 1) * 0.2 * b;
        })}
        rotation-y={to([subtleMotion.value, zoom.value], (a: any, b: any) => {
          return (a[0] * 2 - 1) * 0.2 * b;
        })}
      >
        <mesh scale={[shirtWidth, shirtHeight, 1]} onClick={handleClick}>
          <planeBufferGeometry />
          <a.meshBasicMaterial
            ref={material}
            transparent
            map={texture}
            depthWrite={false}
            opacity={to(
              [activeAmount, opacity.value],
              (a: any, b: any) => a * b
            )}
            side={FrontSide}
          />
        </mesh>
      </a.group>
    </a.group>
  );
});

export default observer(() => {
  const { active } = useContext(SectionContext);

  const store = useStore();

  const handleShirtClick = useCallback((id: number) => {
    store.setViewedShirt(id);
  }, []);

  return (
    <group scale={[1.5, 1.5, 1.5]} position-y={-2} visible={active}>
      {range(numShirts).map((shirtId) => (
        <group
          key={shirtId}
          rotation-y={-((shirtId - 1.65) / numShirts) * Math.PI * 2}
        >
          <group position-z={ringRadius} rotation-y={Math.PI}>
            <TShirt
              id={shirtId}
              onClick={
                active && store.shirts[shirtId].isReleased
                  ? handleShirtClick
                  : undefined
              }
              isViewing={store.viewedShirtId === shirtId}
            />
          </group>
        </group>
      ))}
    </group>
  );
});

import { a, useSpring } from "@react-spring/web";
import * as easings from "d3-ease";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../store";

type Props = {};

export default observer(({}: Props) => {
  const store = useStore();
  const { activeSection, viewedPostcardId, currentLanguage } = store;

  const { t } = useTranslation();

  const isActive = activeSection === 1 && viewedPostcardId >= 0;

  const opacity = useSpring({
    value: isActive ? 1 : 0,
    config: {
      duration: 500,
      easing: easings.easeCubicInOut,
    },
    native: true,
  });

  return (
    <a.div
      className="zoomButtonsContainer"
      style={{
        //@ts-ignore
        opacity: opacity.value,
        visibility: isActive ? "visible" : "hidden",
      }}
    >
      <div style={{ flex: 1 }} />
      <button
        onClick={() => store.setViewedPostcard(-1)}
        className="textButton"
      >
        {t("return")}
      </button>
    </a.div>
  );
});

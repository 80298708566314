import { a, useSpring } from "@react-spring/web";
import * as easings from "d3-ease";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { ReactComponent as GoArrow } from "../assets/svg/SVD-10-arrow-go-v2.svg";
import { useStore } from "../store";
import arrow from "../assets/images/SVD-10-arrow-go-v2.png";

type Props = {};

export default observer(({}: Props) => {
  const store = useStore();
  const {
    viewedShirtId,
    activeSection,
    activeShirtId,

    shirts,
    currentLanguage,
  } = store;

  const { t } = useTranslation();

  const shirt = shirts[viewedShirtId];

  const isActive = activeSection === 2 && viewedShirtId >= 0;

  const opacity = useSpring({
    value: isActive ? 1 : 0,
    config: {
      duration: 500,
      easing: easings.easeCubicInOut,
    },
    native: true,
  });

  const productPageUrl = shirt?.storeUrl[currentLanguage];

  return (
    <a.div
      className="zoomButtonsContainer"
      style={{
        //@ts-ignore
        opacity: opacity.value,
        visibility: isActive ? "visible" : "hidden",
      }}
    >
      {
        <a href={productPageUrl} target="_blank">
          <button className="textButton borderedButton">
            {t("goToProductPage")}
            <img
              src={arrow}
              style={{
                marginLeft: 10,
                width: 20,
                height: 5,
              }}
            />
          </button>
        </a>
      }
      <button onClick={() => store.setViewedShirt(-1)} className="textButton">
        {t("return")}
      </button>
    </a.div>
  );
});

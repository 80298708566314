import { useDetectGPU } from "@react-three/drei";
import range from "lodash/range";
import { observer } from "mobx-react";
import { trace } from "node:console";
import React, { memo, useContext, useEffect, useMemo, useRef } from "react";
import { Object3DNode, useFrame, useThree } from "react-three-fiber";
import { Color, Group, Object3D } from "three";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import theme from "../constants/theme";
import { scale } from "../helpers/math";
import Perlin from "../helpers/Perlin";
import { useStore } from "../store";
import { SectionContext } from "./Section";

const numSegments = 50;

const perlin = new Perlin();

type Props = {
  numRings: number;
  index: number;
  color: string;
  radius: number;
  moveSpeed: number;
  lineThickness: number;
  dashSize: number;
  gapSize: number;
  lineWobble: number;
} & Object3DNode<Object3D, typeof Object3D>;

const Ring = memo(
  ({
    numRings,
    index,
    color,
    radius,
    moveSpeed,
    lineThickness,
    dashSize,
    gapSize,
    lineWobble,
    ...props
  }: Props) => {
    const { activeAmount, active } = useContext(SectionContext);

    const ref = useRef<any>();
    const [line, geometry, material] = useMemo(() => {
      const positions = [];

      const noiseOffset = Math.random() * 100;

      for (let i = 0; i <= numSegments; i++) {
        const angle = (i / numSegments) * Math.PI * 2;
        const warpedRadius =
          radius *
            scale(
              perlin.getValue(angle + noiseOffset),
              -lineWobble,
              lineWobble,
              0.7,
              1.3
            ) +
          (Math.cos(angle * 4) * 0.9 * index) / numRings;
        const x = Math.cos(angle) * warpedRadius;
        const y = Math.sin(angle) * warpedRadius;

        positions.push(
          x,
          y,
          perlin.getValue(angle + noiseOffset + 564123) * lineWobble * 0.1
        );
      }

      const geometry = new LineGeometry();
      geometry.setPositions(positions);

      const hex = new Color(color).getHex();

      const material = new LineMaterial({
        color: hex,
        linewidth: lineThickness,
        vertexColors: false,
        dashed: true,
      });

      material.fragmentShader = `
      uniform vec3 diffuse;
		uniform float opacity;

		#ifdef USE_DASH

			uniform float dashSize;
			uniform float dashOffset;
			uniform float gapSize;

		#endif

		varying float vLineDistance;

		#include <common>
		#include <color_pars_fragment>
		#include <fog_pars_fragment>
		#include <logdepthbuf_pars_fragment>
		#include <clipping_planes_pars_fragment>

		varying vec2 vUv;

		void main() {

			// #include <clipping_planes_fragment>

			#ifdef USE_DASH

				// if ( vUv.y < - 1.0 || vUv.y > 1.0 ) discard; // discard endcaps

				if ( mod( vLineDistance + dashOffset, dashSize + gapSize ) > dashSize ) discard; // todo - FIX

			#endif

			if ( abs( vUv.y ) > 1.0 ) {

				float a = vUv.x;
				float b = ( vUv.y > 0.0 ) ? vUv.y - 1.0 : vUv.y + 1.0;
				float len2 = a * a + b * b;

				// if ( len2 > 1.0 ) discard;

			}

			vec4 diffuseColor = vec4( diffuse, opacity );
			gl_FragColor = vec4( diffuseColor.rgb, diffuseColor.a );
		}
      `;

      material.defines.USE_DASH = "";
      material.dashSize = dashSize;
      material.gapSize = gapSize;
      material.linewidth = lineThickness;

      material.dashOffset += Math.random() * 1000;

      const line = new Line2(geometry, material);
      line.computeLineDistances();
      line.scale.set(1, 1, 1);
      return [line, geometry, material];
    }, []);

    const offset = useRef(0);
    const frameSkip = useRef(Math.floor(Math.random() * 5 + 1));
    useFrame(() => {
      if (active) {
        if (offset.current % frameSkip.current == 0)
          material.dashOffset += moveSpeed * frameSkip.current;

        offset.current += 1;
        material.linewidth = lineThickness * activeAmount.get();
        material.resolution.set(window.innerWidth, window.innerHeight);
      }
    });

    return (
      <primitive ref={ref} object={line} {...props}>
        <primitive object={geometry} />
        <primitive object={material} depthWrite={true} />
      </primitive>
    );
  }
);

export default () => {
  const store = useStore();
  const { active } = useContext(SectionContext);

  const group = useRef<Group>();

  const gpu = useDetectGPU();
  const numRingsToRender = (gpu?.tier || 1) * 30;

  useEffect(() => {
    store.setGalaxyReady();
  }, []);

  return (
    <group ref={group} visible={active}>
      {range(numRingsToRender).map((i) => {
        const norm = i / numRingsToRender;
        const radius = norm * 2 + 2;

        const lineThickness = scale(Math.pow(norm, 0.6), 0, 1, 5, 2);

        const moveSpeed =
          scale(Math.pow(norm, 2), 0, 1, 0.3, 1.9) *
          0.2 *
          (Math.random() > 0.5 ? -1 : 1);

        const group = Math.floor(i / (numRingsToRender / 6));
        const rotate = group % 2 == 0;
        const color = theme.galaxy[Math.floor(i / (numRingsToRender / 3))];

        const dashSize = scale(Math.random(), 0, 1, 0.3, 1);
        const gapSize = scale(Math.random(), 0, 1, 1, i * 1);

        return (
          <Ring
            key={i}
            index={i}
            numRings={numRingsToRender}
            renderOrder={-i}
            rotation-x={!rotate ? Math.PI / 2 : 0}
            rotation-y={
              rotate ? scale(Math.random(), 0, 1, -Math.PI / 8, Math.PI / 8) : 0
            }
            scale-y={1}
            color={color}
            radius={radius + group * 0.2}
            moveSpeed={moveSpeed}
            lineThickness={lineThickness}
            dashSize={dashSize}
            gapSize={gapSize}
            lineWobble={Math.random() > 0.9 ? 6 : 3}
          />
        );
      })}
    </group>
  );
};

import { SpringValue } from "@react-spring/core";
import { a } from "@react-spring/three";
import React, {
  createContext,
  memo,
  ReactNode,
  Suspense,
  useContext,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useFrame } from "react-three-fiber";
import type { Group } from "three";
import { sectionScalePower } from "../constants/misc";
import { Context } from "./MainCanvas";
import Ring from "./Ring";
import { useMediaQuery } from "react-responsive";

type SectionContextType = {
  active: boolean;
  activeAmount: any;
  index: number;
};

export const SectionContext = createContext<SectionContextType>({
  active: false,
  activeAmount: new SpringValue(),
  index: 0,
});

const activeSectionWidth = 0.5;
const activeSectionFadeTime = 0.5;

export function getActiveAmount(
  activeSectionSmooth: number,
  sectionIndex: number
) {
  // active
  if (
    activeSectionSmooth >= sectionIndex &&
    activeSectionSmooth < sectionIndex + activeSectionWidth
  )
    return 1;

  //fade out
  if (
    activeSectionSmooth >= sectionIndex + activeSectionWidth &&
    activeSectionSmooth <
      sectionIndex + activeSectionWidth + activeSectionFadeTime
  )
    return (
      1 -
      (activeSectionSmooth - (sectionIndex + activeSectionWidth)) /
        activeSectionFadeTime
    );

  //fade in
  if (
    activeSectionSmooth >= sectionIndex - activeSectionFadeTime &&
    activeSectionSmooth < sectionIndex
  )
    return (
      (activeSectionSmooth - (sectionIndex - activeSectionFadeTime)) /
      activeSectionFadeTime
    );
  else return 0;
}

const sectionNames = ["intro", "past", "present", "future"];

export type Props = {
  index: number;
  children?: ReactNode;
  noRing?: boolean;
};

export default memo(({ children, index, noRing }: Props) => {
  const { activeSectionSmooth, subtleMotion } = useContext(Context);
  const { t } = useTranslation();
  const [active, setActive] = useState(true);

  const scale = Math.pow(sectionScalePower, index);

  const group = useRef<Group>();

  useFrame(() => {
    const val = activeSectionSmooth.get();
    const curr = Math.floor(val),
      next = Math.ceil(val);

    setActive(curr === index || next === index);
  });

  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1200px)" });
  const isMediumScreen = useMediaQuery({ query: "(min-device-width: 450px)" });

  return (
    <SectionContext.Provider
      value={{
        active,
        activeAmount: activeSectionSmooth.to((v: any) =>
          getActiveAmount(v, index)
        ),
        index,
      }}
    >
      <a.group
        ref={group}
        scale={[scale, scale, scale]}
        rotation-y={(index * Math.PI * 2) / 3}
      >
        {children}
        {!noRing && (
          <Ring
            radius={isBigScreen ? 4 : isMediumScreen ? 3 : 2.5}
            innerText={t(`${sectionNames[index]}RingInner`)}
            outerText={t(`${sectionNames[index]}RingOuter`)}
            position-y={1}
          />
        )}
      </a.group>
    </SectionContext.Provider>
  );
});

import range from "lodash/range";
import { observer } from "mobx-react";
import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../store";
import { SectionContext } from "../../Section";
import Postcard from "./Postcard";

const maxNumCardsPerRow = 6;
const rowHeight = 1;

export default observer(() => {
  const store = useStore();
  let { postcards, numCardsToRender } = store;
  const { active } = useContext(SectionContext);

  const numRows = Math.ceil(postcards.length / maxNumCardsPerRow);

  const handlePostcardClick = useCallback((id: number) => {
    store.setViewedPostcard(id);
  }, []);

  return (
    <group scale={[2.3, 2.3, 2.3]} position-y={-3.3} visible={active}>
      {range(numRows).map((rowIndex) => (
        <group key={rowIndex} position-y={rowIndex * rowHeight}>
          {range(maxNumCardsPerRow).map((cardIndex) => {
            const cardId = rowIndex * maxNumCardsPerRow + cardIndex;

            if (cardId > numCardsToRender) return null;

            const postcard =
              postcards[rowIndex * maxNumCardsPerRow + cardIndex];

            return postcard ? (
              <group
                key={cardIndex}
                rotation-y={(cardIndex / maxNumCardsPerRow) * Math.PI * 2}
              >
                <group position-z={0.7} rotation-x={-0.2}>
                  <Postcard
                    id={cardId}
                    onClick={handlePostcardClick}
                    isViewing={store.viewedPostcardId === cardId}
                  />
                </group>
              </group>
            ) : null;
          })}
        </group>
      ))}
    </group>
  );
});
